import type { ComponentProps, ForwardedRef, JSX, ReactNode } from 'react';

import { Input as InputBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Box } from '@change-corgi/design-system/layout';
import { generateBreakpointAttributes, normalizeResponsiveValue } from '@change-corgi/design-system/theme';
import type { FormSize, ResponsiveValue } from '@change-corgi/design-system/theme';

type Props = Omit<ComponentProps<typeof InputBase>, 'size' | 'type'> & {
	error?: ReactNode;
	size?: ResponsiveValue<FormSize>;
	type?: 'text' | 'password' | 'search' | 'email' | 'number' | 'tel' | 'url' | 'date' | 'datetime-local';
};

function BareInputInner(
	{ error, variant, size, as, ...rest }: Props,
	ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
	const sizeResponsive = normalizeResponsiveValue(size);
	if (as) {
		const { onChange, ...inputBaseProps } = rest;
		// handling cases for overriding the default input (e.g. for stripe forms)
		return (
			<InputBase
				as="div"
				data-error={!!error}
				{...generateBreakpointAttributes('data-size', (idx) => sizeResponsive[idx] || 'default')}
				ref={ref}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{...(inputBaseProps as any)}
			>
				<Box as={as} onChange={onChange} />
			</InputBase>
		);
	}
	return (
		<InputBase
			data-error={!!error}
			{...generateBreakpointAttributes('data-size', (idx) => sizeResponsive[idx] || 'default')}
			ref={ref}
			{...rest}
		/>
	);
}

/**
 * @doc $DOC:BareInput
 */
export const BareInput = forwardRef(BareInputInner);
