/*
 * This file is generated by icons/template.js
 */
const SvgThumbUpNofill20Px = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
		<g clipPath="url(#thumb_up_nofill_20px_svg__a)">
			<path
				fill="#707070"
				d="M7.5 17.5H15c.692 0 1.283-.417 1.533-1.017l2.517-5.875c.075-.191.117-.391.117-.608V8.333c0-.916-.75-1.666-1.667-1.666h-5.258l.791-3.809.025-.266c0-.342-.141-.659-.366-.884l-.884-.875-5.491 5.492c-.3.3-.484.717-.484 1.175v8.333c0 .917.75 1.667 1.667 1.667m0-10 3.617-3.617L10 8.333h7.5V10L15 15.833H7.5zm-6.667 0h3.334v10H.833z"
			/>
		</g>
		<defs>
			<clipPath id="thumb_up_nofill_20px_svg__a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgThumbUpNofill20Px.displayName = 'iconThumbUpNofill20Px';
export default SvgThumbUpNofill20Px;
