/*
 * This file is generated by icons/template.js
 */
const SvgNextdoor = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#nextdoor_svg__a)">
			<path fill="currentColor" d="M0 5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v14a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5z" />
			<path
				fill="#fff"
				d="M8.701 8.488c1.037-1.565 2.908-2.58 5.084-2.58 3.367 0 5.969 2.443 5.969 5.556v6.52a.3.3 0 0 1-.29.293h-2.686a.29.29 0 0 1-.289-.293V11.93c0-1.342-1.02-2.873-2.704-2.873-1.768 0-2.703 1.531-2.703 2.873v6.056a.29.29 0 0 1-.29.292H8.107a.29.29 0 0 1-.272-.19c-.017-.034-.017-.068-.017-.12v-5.866a.4.4 0 0 0-.29-.379c-2.363-.653-3.23-2.477-3.28-5.092 0-.12.067-.223.186-.275.034-.017.068-.017.12-.017H6.66l.646.017c.153 0 .289.12.289.292.034 1.067.238 1.652.731 1.944a.28.28 0 0 0 .374-.103"
			/>
		</g>
		<defs>
			<clipPath id="nextdoor_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgNextdoor.displayName = 'iconNextdoor';
export default SvgNextdoor;
