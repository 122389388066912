/*
 * This file is generated by icons/template.js
 */
const SvgThumbDownOffAlt = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="m10.89 18.28.57-2.89c.12-.59-.04-1.2-.42-1.66S10.1 13 9.5 13H4v-1.08L6.57 6h8.09c.18 0 .34.16.34.34v7.84zM10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22zm10-7h2V4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1" />
	</svg>
);
SvgThumbDownOffAlt.displayName = 'iconThumbDownOffAlt';
export default SvgThumbDownOffAlt;
