import type { JSX, PropsWithChildren } from 'react';
import { useState } from 'react';

import type { FakeHistory } from '@change-corgi/core/react/router';

import { ModalsContextProvider } from './context';
import { ModalsList } from './ModalsList';
import type { ModalConfig } from './shared/types';

/**
 * @doc $DOC:ModalsContainer
 */
export function ModalsContainer({ children, history }: PropsWithChildren<{ history?: FakeHistory }>): JSX.Element {
	const [modal, setModal] = useState<ModalConfig | undefined>(undefined);

	return (
		<ModalsContextProvider value={{ modal, setModal, history }}>
			{children}
			<ModalsList />
		</ModalsContextProvider>
	);
}
