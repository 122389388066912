/*
 * This file is generated by icons/template.js
 */
const SvgThumbsDownFill24Px = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#thumbs_down_fill_24px_svg__a)">
			<path
				fill="#707070"
				d="m10.88 21.94 5.53-5.54c.37-.37.58-.88.58-1.41V5c0-1.1-.9-2-2-2H6c-.8 0-1.52.48-1.83 1.21L.91 11.82C.06 13.8 1.51 16 3.66 16h5.65l-.95 4.58c-.1.5.05 1.01.41 1.37.59.58 1.53.58 2.11-.01M21 3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2s2-.9 2-2V5c0-1.1-.9-2-2-2"
			/>
		</g>
		<defs>
			<clipPath id="thumbs_down_fill_24px_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgThumbsDownFill24Px.displayName = 'iconThumbsDownFill24Px';
export default SvgThumbsDownFill24Px;
