/*
 * This file is generated by icons/template.js
 */
const SvgTwitter = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M17.751 3h3.067l-6.7 7.658L22 21.078h-6.172l-4.833-6.32-5.531 6.32h-3.07l7.167-8.19L2 3h6.328l4.37 5.777zm-1.076 16.243h1.7L7.404 4.739H5.58z"
		/>
	</svg>
);
SvgTwitter.displayName = 'iconTwitter';
export default SvgTwitter;
