/*
 * This file is generated by icons/template.js
 */
const SvgLinktree = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
		<g clipPath="url(#linktree_svg__a)">
			<path
				fill="currentColor"
				d="M13.7 21.736h4.628v10.452H13.7zM3 11.024h7.876l-5.6-5.337 3.099-3.185 5.337 5.486v-7.8h4.628v7.8l5.338-5.474 3.095 3.173-5.596 5.325h7.873v4.404h-7.918l5.634 5.474L23.678 24l-7.652-7.688L8.375 24l-3.098-3.098 5.636-5.474H3z"
			/>
		</g>
		<defs>
			<clipPath id="linktree_svg__a">
				<path fill="#fff" d="M0 0h32v32H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgLinktree.displayName = 'iconLinktree';
export default SvgLinktree;
