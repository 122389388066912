/*
 * This file is generated by icons/template.js
 */
const SvgVictory = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M339.255 202.555c-10.362.76-18.81-7.209-19.542-17.57-.704-9.659 6.533-18.275 16.67-19.007 10.362-.76 18.781 6.73 19.485 16.416.732 10.108-5.547 19.372-16.613 20.16m-41.336-13.15c-3.35 4.308-7.574 7.884-12.418 10.7-7.462 4.365-16.472 6.983-26.327 7.715-28.496 2.056-52.261-12.39-54.486-43.504l-2.675-36.802c-2.224-31.03 19.007-48.713 47.446-50.825l.17-.028c28.213-2.028 51.725 12.08 54.428 42.49 0 0 .029.282.029.338.056.873-.198 1.718-.789 2.393-.197.226-.45.423-.704.592a3.77 3.77 0 0 1-1.802.647l-25.877 1.859c-.648.056-1.267-.113-1.802-.366-.901-.423-1.661-1.183-1.915-2.168-.056-.31-.169-.62-.253-.93a44 44 0 0 0-1.155-3.548c-3.069-6.786-10.052-11.29-17.908-10.728-10.024.732-17.57 9.433-16.839 19.457l2.45 33.959c.732 10.024 9.433 17.542 19.457 16.838 8.73-.647 15.572-7.32 16.726-15.684 0-.056.169-1.182.197-1.69.197-1.632 1.577-2.928 3.323-3.068l25.905-1.859a3.8 3.8 0 0 1 2.675.873 3.34 3.34 0 0 1 1.07 1.971c.028.14.084 1.83.084 2.084.254 11.91-3.097 21.738-9.01 29.284M43.526 36.15l94.217 451.512c2.365 11.404-4.928 22.583-16.36 24.976-1.436.31-2.9.45-4.336.45-9.8 0-18.556-6.842-20.64-16.81L2.163 44.766c-2.366-11.404 4.955-22.61 16.36-25.004 11.431-2.309 22.61 4.956 25.003 16.388m33.213-6.428c10.812-7.124 96.553-54.767 387.931.366 1.323.253 2.168 1.633 1.746 2.9l-44.968 137.777a2.3 2.3 0 0 0 .197 1.83l88.697 148.73c1.042 1.746-.563 3.857-2.506 3.294-37.534-11.122-259.165-73.154-372.275-22.526-1.268.591-2.704-.14-2.985-1.52L75.838 32.03c-.197-.93.14-1.803.9-2.31"
		/>
	</svg>
);
SvgVictory.displayName = 'iconVictory';
export default SvgVictory;
