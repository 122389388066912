/*
 * This file is generated by icons/template.js
 */
const SvgThumbDownNofill20Px = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
		<g clipPath="url(#thumb_down_nofill_20px_svg__a)">
			<path
				fill="#707070"
				d="M12.5 2.5H5c-.692 0-1.283.417-1.533 1.017L.95 9.392A1.7 1.7 0 0 0 .833 10v1.667c0 .916.75 1.666 1.667 1.666h5.258l-.791 3.809-.025.266c0 .342.141.659.366.884l.884.875 5.491-5.492c.3-.3.484-.717.484-1.175V4.167c0-.917-.75-1.667-1.667-1.667m0 10-3.617 3.617L10 11.667H2.5V10L5 4.167h7.5zm3.333-10h3.334v10h-3.334z"
			/>
		</g>
		<defs>
			<clipPath id="thumb_down_nofill_20px_svg__a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgThumbDownNofill20Px.displayName = 'iconThumbDownNofill20Px';
export default SvgThumbDownNofill20Px;
