/*
 * This file is generated by icons/template.js
 */
const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z" />
	</svg>
);
SvgDownload.displayName = 'iconDownload';
export default SvgDownload;
