import type { ThemeUIStyleObject } from 'theme-ui';

export type ContainerVariant = 'default' | 'small';

/**
 * Relationship between breakpoints ranges (end exclusive) and container widths:
 *
 * | Breakpoint  | Container   | Margin |
 * | --          | --          | --     |
 * | 0 - 768     | none (full) | 0      |
 * | 768 - 1024  | 740         | 32     |
 * | 1024 - 1280 | 960         | 32     |
 * | 1280 - inf  | 1216        | 32     |
 *
 * In these calculations the "margin" is not the result of a CSS margin/padding rule. It is simply:
 *
 * `(min(breakpoint) - container)/2`
 *
 * which is how much empty space will be visible on each side once the breakpoint is reached. The container is narrower
 * than breakpoint to ensure this margin space is consistent, so that content of the container does not hug the edge of
 * the window or overlap with a scrollbar.
 *
 * Note that applying margin/padding to elements within a Container will result in _additional_ space. You may want to
 * only add this space on the smallest breakpoint (where the container margin is zero). We may want to update this
 * container to include a default margin for small screens, but that will require reviewing any pages that have custom
 * spacing for small screens.
 *
 * See also: [container](./breakpoints.ts)
 */
const containerDefault: ThemeUIStyleObject = {
	maxWidth: 1216,
	width: [null, '740px', '960px', '1216px'],
};

export const layout: Record<'container' | `container_${ContainerVariant}`, ThemeUIStyleObject> = {
	container: containerDefault,
	container_default: containerDefault,
	container_small: {
		maxWidth: 960,
		width: [null, '740px', '960px'],
	},
};

// This is included so that theme-ui doesn't set an invalid max-width: 'container' property on the container element.
// It doesn't seem to be setting max-width: 960px as it should, though, so it's set above in the default variant styles.
export const sizes: Record<'container' | `container_${ContainerVariant}`, ThemeUIStyleObject> = {
	container: {
		maxWidth: 1216,
	},
	container_default: {
		maxWidth: 1216,
	},
	container_small: {
		maxWidth: 960,
	},
};
