/*
 * This file is generated by icons/template.js
 */
const SvgMenu = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z" />
	</svg>
);
SvgMenu.displayName = 'iconMenu';
export default SvgMenu;
