/*
 * This file is generated by icons/template.js
 */
const SvgTelegram = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
		<g clipPath="url(#telegram_svg__a)">
			<path
				fill="currentColor"
				d="m12.557 20.242-.53 7.445c.758 0 1.086-.325 1.48-.716l3.55-3.393 7.358 5.388c1.349.752 2.3.356 2.663-1.242l4.83-22.629.001-.001c.428-1.995-.721-2.775-2.036-2.286L1.487 13.676c-1.938.752-1.908 1.832-.33 2.322l7.258 2.257L25.272 7.707c.793-.525 1.515-.235.921.29z"
			/>
		</g>
		<defs>
			<clipPath id="telegram_svg__a">
				<path fill="#fff" d="M0 0h32v32H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgTelegram.displayName = 'iconTelegram';
export default SvgTelegram;
