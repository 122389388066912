import { useMemo } from 'react';
import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import styled from '@emotion/styled';
import type { Location } from 'react-router';
import { useLocation } from 'react-router';
import styleToJs from 'style-to-js';

import { forwardRef, memo } from '@change-corgi/core/react/core';
import type { HtmlParserReplaceFn, HtmlTransformer } from '@change-corgi/core/react/html';
import { parseHtml } from '@change-corgi/core/react/html';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { Link } from '@change-corgi/design-system/components/actions';
import { Box } from '@change-corgi/design-system/layout';
import type { LinkVariant } from '@change-corgi/design-system/theme';
import { Text } from '@change-corgi/design-system/typography';

import { isSamePageHref } from './shared/isSamePageHref';
import { isValidHref } from './shared/isValidHref';

type ReplaceTagsOptions = {
	onClickLink?: () => void;
	linkVariant?: LinkVariant;
	stripLinks?: boolean;
	/**
	 * for security concerns, hash links are by default handled as normal link that will result in a normal navigation
	 *
	 * this can be overridden for trusted content to navigate to the target on the current page
	 */
	supportHashLinks?: boolean;
	/**
	 * for security concerns, links are open in a new window or tab (_blank)
	 *
	 * this can be overridden for trusted content to open them in the current window or tab
	 */
	linksTarget?: '_blank' | '_self';
	/**
	 * transforms the href of links found in the html
	 *
	 * can be useful to process links, for instance to remove the www.change.org domain
	 */
	transformLinkHref?: (href: string) => string;
	reportBadLinks?: boolean;
	reportParams?: Record<string, string | undefined | number>;
	transformers?: HtmlTransformer[];
	/**
	 * By default, only some tags are allowed
	 *
	 * if this is true, the default restrictions of dompurify will apply
	 */
	noRestrictedTags?: boolean;
	/**
	 * By default, only some attributes are allowed
	 *
	 * if this is true, the default restrictions of dompurify will apply
	 */
	noRestrictedAttrs?: boolean;
	/**
	 * By default, some styles are applied to content (e.g. ul/ol, iframes, video/img, ...)
	 *
	 * if this is true, those styles will not be applied
	 */
	noContentStyles?: boolean;
	/**
	 * By default, iframes are not allowed
	 */
	allowIframes?: boolean;
};

type Props = Omit<ComponentPropsWithRef<typeof Text>, 'variant' | 'children' | 'ellipsis'> &
	ReplaceTagsOptions & {
		html: string;
	};

export const RESTRICTED_TEXT_TAGS = ['strong', 'em', 'b', 'i', 'p', 'ul', 'ol', 'li'];
export const RESTRICTED_TAGS = ['br', 'a', ...RESTRICTED_TEXT_TAGS];
export const RESTRICTED_ATTRS = ['href'];
export const ALLOWED_IFRAME_ATTRS = ['src', 'scrolling', 'frameborder', 'allowfullscreen', 'width', 'height'];

const CustomText = styled(Text)`
	ul,
	ol {
		margin: 1.5em 3em;
	}
	ul {
		list-style-type: circle;
	}
	ol {
		list-style-type: decimal;
	}
	ul > li,
	ol > li {
		margin: 0.75em 0.5em;
	}
	p + p {
		margin-top: 1.5em;
	}
	img,
	video,
	iframe {
		max-width: 100%;
	}
`;

// Warning:
// Styles need to be generated outside of the genReplaceTags function for consistent class generation between server and client (for SSR)
// For that reason, do not use `sx` inside this function.
// Instead, define a wrapping component (using another component or styled()) outside of the function, then use that component inside the function.
export function genReplaceTags(
	{
		onClickLink,
		linkVariant,
		stripLinks,
		reportBadLinks,
		supportHashLinks,
		linksTarget,
		reportParams,
		transformLinkHref,
	}: ReplaceTagsOptions,
	location: Location,
	utilityContext: UtilityContext,
): HtmlParserReplaceFn {
	// eslint-disable-next-line complexity
	return function replaceTags({ name, attribs, children }, { domToReact }) {
		if (name === 'a') {
			const { href: hrefOption, class: className, style, ...restAttribs } = attribs;
			const href =
				transformLinkHref && !!hrefOption && isValidHref(hrefOption) ? transformLinkHref(hrefOption) : hrefOption;
			const badLink = !href || !isValidHref(href);
			if (badLink && reportBadLinks) {
				void utilityContext.errorReporter.report({
					error: 'Invalid href in parsed HTML',
					params: {
						href: href || '(no href)',
						...reportParams,
					},
				});
			}
			if (stripLinks || badLink) {
				return <span>{domToReact(children)}</span>;
			}
			if (supportHashLinks && isSamePageHref(href, location, utilityContext)) {
				return (
					<Link
						variant={linkVariant}
						// there is what could be a bug in react-router where hash links ignore the current query params
						to={href.startsWith('#') ? `${location.pathname}${location.search}${href}` : href}
						style={(style && styleToJs(style)) || undefined}
						className={className}
						{...restAttribs}
						onClick={onClickLink}
						// forcing external mode to avoid using react router for hash-links
						forceMode="external"
					>
						{domToReact(children)}
					</Link>
				);
			}
			return (
				<Link
					variant={linkVariant}
					to={href}
					target={linksTarget || '_blank'}
					style={(style && styleToJs(style)) || undefined}
					className={className}
					{...restAttribs}
					onClick={onClickLink}
					// forcing external mode to avoid using react router for hash-links
					// only useful when linksTarget is _self
					forceMode={href.includes('#') ? 'external' : undefined}
				>
					{domToReact(children)}
				</Link>
			);
		}

		return undefined;
	};
}

function HtmlInner(
	{
		html,
		onClickLink,
		linkVariant,
		stripLinks,
		supportHashLinks,
		linksTarget,
		transformLinkHref,
		reportBadLinks,
		reportParams,
		as,
		transformers,
		noRestrictedTags,
		noRestrictedAttrs,
		noContentStyles,
		allowIframes,
		...rest
	}: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	const location = useLocation();
	const utilityContext = useUtilityContext();

	const { restrictedTags, restrictedAttrs, allowedTags, allowedAttrs } = useMemo(() => {
		const tags = noRestrictedTags ? undefined : RESTRICTED_TAGS;
		const attrs = noRestrictedAttrs ? undefined : RESTRICTED_ATTRS;
		const allowed = allowIframes ? { tags: ['iframe'], attrs: ALLOWED_IFRAME_ATTRS } : undefined;
		if (!transformers) {
			return { restrictedAttrs: attrs, restrictedTags: tags, allowedTags: allowed?.tags, allowedAttrs: allowed?.attrs };
		}
		return {
			restrictedTags: !tags
				? undefined
				: ([] as string[]).concat(tags, ...transformers.map((transformer) => transformer.transformedTags || [])),
			restrictedAttrs: !attrs
				? undefined
				: ([] as string[]).concat(attrs, ...transformers.map((transformer) => transformer.transformedAttrs || [])),
			allowedTags: allowed?.tags,
			allowedAttrs: allowed?.attrs,
		};
	}, [transformers, noRestrictedTags, noRestrictedAttrs, allowIframes]);

	const replace = useMemo(
		() =>
			genReplaceTags(
				{
					onClickLink,
					linkVariant,
					stripLinks,
					reportBadLinks,
					supportHashLinks,
					linksTarget,
					transformLinkHref,
					reportParams,
				},
				location,
				utilityContext,
			),
		[
			onClickLink,
			linkVariant,
			stripLinks,
			reportBadLinks,
			supportHashLinks,
			linksTarget,
			transformLinkHref,
			reportParams,
			location,
			utilityContext,
		],
	);

	const Container = noContentStyles ? Box : CustomText;

	return (
		<Container as={as || 'div'} {...rest} ref={ref}>
			{parseHtml(html, {
				replace,
				restrictedTags,
				restrictedAttrs,
				allowedTags,
				allowedAttrs,
				transformers,
			})}
		</Container>
	);
}

/**
 * @doc $DOC:Html
 */
export const Html = memo(forwardRef(HtmlInner));
