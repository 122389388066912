/*
 * This file is generated by icons/template.js
 */
const SvgInstagram = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M12 2.983c2.937 0 3.285.01 4.446.064 2.98.136 4.373 1.55 4.509 4.509.053 1.16.063 1.508.063 4.445s-.01 3.285-.063 4.445c-.137 2.956-1.525 4.373-4.51 4.509-1.16.053-1.506.064-4.445.064-2.937 0-3.285-.01-4.445-.064-2.988-.137-4.373-1.557-4.509-4.51-.053-1.16-.064-1.507-.064-4.445 0-2.937.012-3.284.064-4.445.137-2.958 1.525-4.373 4.51-4.509 1.16-.052 1.507-.063 4.444-.063M12 1c-2.987 0-3.361.013-4.535.066-3.995.183-6.215 2.4-6.398 6.398C1.013 8.64 1 9.013 1 12s.013 3.362.066 4.536c.183 3.994 2.4 6.215 6.398 6.398C8.64 22.987 9.013 23 12 23s3.362-.013 4.536-.066c3.99-.183 6.217-2.4 6.397-6.398.054-1.174.067-1.549.067-4.536s-.013-3.361-.066-4.535c-.18-3.99-2.399-6.215-6.397-6.398C15.362 1.013 14.987 1 12 1m0 5.352a5.649 5.649 0 1 0 0 11.297A5.649 5.649 0 0 0 12 6.35m0 9.315a3.666 3.666 0 1 1 0-7.333 3.666 3.666 0 0 1 0 7.333m5.872-10.858a1.32 1.32 0 1 0 0 2.64 1.32 1.32 0 0 0 0-2.64"
		/>
	</svg>
);
SvgInstagram.displayName = 'iconInstagram';
export default SvgInstagram;
