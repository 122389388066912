/*
 * This file is generated by icons/template.js
 */
const SvgQuote = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 37" {...props}>
		<path d="M17.922 36.62H.01V19.166q-.114-3.765 1.028-7.074 1.257-3.307 3.427-5.818a18 18 0 0 1 5.141-4.106A18.4 18.4 0 0 1 16.232 0v7.53q-4.341 1.482-5.94 4.448-1.6 2.853-1.6 7.073h9.23zm22.078 0H22.089V19.166q-.114-3.765 1.028-7.074 1.256-3.307 3.427-5.818a18 18 0 0 1 5.14-4.106A18.4 18.4 0 0 1 38.31 0v7.53q-4.341 1.482-5.94 4.448-1.6 2.853-1.6 7.073H40z" />
	</svg>
);
SvgQuote.displayName = 'iconQuote';
export default SvgQuote;
