/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from '@change-corgi/core/react/context';
import type { FakeHistory } from '@change-corgi/core/react/router';

import type { ModalConfig } from './shared/types';

export const {
	Context: ModalsContext,
	Provider: ModalsContextProvider,
	useContext: useModalsContext,
} = createContext<{
	history?: FakeHistory;
	modal: ModalConfig<any, any, any> | undefined;
	setModal: (
		setModal: (modal: ModalConfig<any, any, any> | undefined) => ModalConfig<any, any, any> | undefined,
	) => void;
}>(
	// these will be overridden in ModalsContainer
	{
		history: undefined,
		modal: undefined,
		setModal: () => {
			/* do nothing as this is the default value to be overridden */
		},
	},
	{
		name: 'ModalsContext',
	},
);
