/*
 * This file is generated by icons/template.js
 */
const SvgThumbsDownFill20Px = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
		<g clipPath="url(#thumbs_down_fill_20px_svg__a)">
			<path
				fill="#707070"
				d="m9.067 18.283 4.608-4.616c.308-.309.483-.734.483-1.175V4.167c0-.917-.75-1.667-1.666-1.667H5c-.667 0-1.267.4-1.525 1.008L.758 9.85c-.708 1.65.5 3.483 2.292 3.483h4.708l-.791 3.817c-.084.417.041.842.341 1.142a1.24 1.24 0 0 0 1.759-.009M17.5 2.5c-.917 0-1.667.75-1.667 1.667v6.666c0 .917.75 1.667 1.667 1.667s1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667"
			/>
		</g>
		<defs>
			<clipPath id="thumbs_down_fill_20px_svg__a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgThumbsDownFill20Px.displayName = 'iconThumbsDownFill20Px';
export default SvgThumbsDownFill20Px;
