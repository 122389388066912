/*
 * This file is generated by icons/template.js
 */
const SvgLine = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 27 24" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M26.094 10.22C26.094 4.585 20.444 0 13.5 0S.906 4.585.906 10.22c0 5.052 4.486 9.284 10.532 10.083.41.089.969.27 1.11.621.127.319.083.818.04 1.14l-.179 1.077-.005.027c-.06.34-.213 1.203 1.096.653 1.345-.565 7.253-4.27 9.894-7.313 1.825-2 2.7-4.036 2.7-6.288m-4.19 3.255h-3.542a.236.236 0 0 1-.236-.236V7.744a.236.236 0 0 1 .236-.236h3.542a.236.236 0 0 1 .236.236v.893a.236.236 0 0 1-.236.236h-2.405V9.8h2.405a.236.236 0 0 1 .236.236v.902a.236.236 0 0 1-.236.236h-2.405v.928h2.405a.236.236 0 0 1 .236.236v.892a.236.236 0 0 1-.236.245m-12.921-.07a.24.24 0 0 1-.167.07l.004-.004H5.278a.236.236 0 0 1-.236-.236V7.742a.236.236 0 0 1 .236-.236h.897a.236.236 0 0 1 .237.236v4.368h2.404a.236.236 0 0 1 .236.236v.893a.24.24 0 0 1-.069.166m1.962-5.908h-.892a.24.24 0 0 0-.24.24v5.497c0 .133.107.24.24.24h.892a.24.24 0 0 0 .241-.24V7.738a.24.24 0 0 0-.24-.241m5.189 0h.892a.237.237 0 0 1 .236.24v5.483a.236.236 0 0 1-.236.236h-.888a.3.3 0 0 1-.064-.009h-.036l-.012-.005h-.007l-.014-.01a.2.2 0 0 1-.061-.061l-2.518-3.4v3.297a.236.236 0 0 1-.236.236h-.894a.236.236 0 0 1-.236-.236V7.772a.236.236 0 0 1 .236-.236h1l.013.007h.007l.013.01h.007l.013.01.014.014.02.025 2.514 3.396V7.733a.236.236 0 0 1 .237-.236"
			clipRule="evenodd"
		/>
	</svg>
);
SvgLine.displayName = 'iconLine';
export default SvgLine;
