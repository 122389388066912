/*
 * This file is generated by icons/template.js
 */
const SvgArrowBack = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z" />
	</svg>
);
SvgArrowBack.displayName = 'iconArrowBack';
export default SvgArrowBack;
