/*
 * This file is generated by icons/template.js
 */
const SvgThumbsUpFill20Px = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
		<g clipPath="url(#thumbs_up_fill_20px_svg__a)">
			<path
				fill="#707070"
				d="M10.933 1.717 6.317 6.333a1.67 1.67 0 0 0-.484 1.175v8.325c0 .917.75 1.667 1.667 1.667H15c.667 0 1.267-.4 1.533-1.008l2.717-6.342c.7-1.65-.508-3.483-2.3-3.483h-4.708l.791-3.817a1.26 1.26 0 0 0-.341-1.142 1.24 1.24 0 0 0-1.759.009M2.5 17.5c.917 0 1.667-.75 1.667-1.667V9.167c0-.917-.75-1.667-1.667-1.667S.833 8.25.833 9.167v6.666c0 .917.75 1.667 1.667 1.667"
			/>
		</g>
		<defs>
			<clipPath id="thumbs_up_fill_20px_svg__a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgThumbsUpFill20Px.displayName = 'iconThumbsUpFill20Px';
export default SvgThumbsUpFill20Px;
