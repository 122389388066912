/*
 * This file is generated by icons/template.js
 */
const SvgThumbDownNofill24Px = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#thumb_down_nofill_24px_svg__a)">
			<path
				fill="#707070"
				d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2m0 12-4.34 4.34L12 14H3v-2l3-7h9zm4-12h4v12h-4z"
			/>
		</g>
		<defs>
			<clipPath id="thumb_down_nofill_24px_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgThumbDownNofill24Px.displayName = 'iconThumbDownNofill24Px';
export default SvgThumbDownNofill24Px;
