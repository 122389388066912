/*
 * This file is generated by icons/template.js
 */
const SvgThumbUpNofill24Px = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#thumb_up_nofill_24px_svg__a)">
			<path
				fill="#707070"
				d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2M9 9l4.34-4.34L12 10h9v2l-3 7H9zM1 9h4v12H1z"
			/>
		</g>
		<defs>
			<clipPath id="thumb_up_nofill_24px_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgThumbUpNofill24Px.displayName = 'iconThumbUpNofill24Px';
export default SvgThumbUpNofill24Px;
