/*
 * This file is generated by icons/template.js
 */
const SvgVk = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 33 20" {...props}>
		<path
			fill="currentColor"
			d="M17.929 20C6.996 20 .759 12.493.5 0h5.477c.18 9.17 4.217 13.053 7.415 13.854V0h5.157v7.908c3.158-.34 6.475-3.944 7.595-7.908H31.3c-.86 4.885-4.458 8.488-7.016 9.97C26.843 11.171 30.94 14.314 32.5 20h-5.677c-1.219-3.804-4.257-6.747-8.274-7.147V20z"
		/>
	</svg>
);
SvgVk.displayName = 'iconVk';
export default SvgVk;
