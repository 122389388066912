/*
 * This file is generated by icons/template.js
 */
const SvgAdd = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
	</svg>
);
SvgAdd.displayName = 'iconAdd';
export default SvgAdd;
