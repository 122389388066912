/* eslint-disable @typescript-eslint/naming-convention */

export type ColorName =
	| 'text' // Body foreground color
	| 'background' // Body background color
	| 'primary' // Primary brand color for links, buttons, etc.
	| 'secondary' // A secondary brand color for alternative styling
	| 'accent' // A contrast color for emphasizing UI
	| 'highlight' // A background color for highlighting text
	| 'muted' // A faint color for backgrounds, borders, and accents that do not require high contrast with the background color
	| 'primary-changeRed'
	| 'primary-white'
	| 'primary-greyBackground'
	| 'primary-black'
	| 'secondary-blue'
	| 'secondary-purple'
	| 'secondary-green'
	| 'secondary-yellow'
	| 'neutral-grey700'
	| 'neutral-grey600'
	| 'neutral-grey400'
	| 'neutral-grey100'
	| 'neutral-grey50'
	| 'typography-primary'
	| 'typography-secondary'
	| 'typography-disabled'
	| 'typography-lightPrimary'
	| 'typography-lightSecondary'
	| 'typography-lightDisabled'
	| 'typography-highlight'
	| 'typography-lightHighlight'
	| 'typography-positive'
	| 'typography-critical'
	| 'status-positive500'
	| 'status-warning500'
	| 'status-critical500'
	| 'status-info900'
	| 'status-info500'
	| 'social-facebook'
	| 'social-gmail'
	| 'social-google'
	| 'social-whatsapp'
	| 'social-twitter'
	| 'social-facebookMessenger'
	| 'social-line'
	| 'social-vk'
	| 'social-instagram'
	| 'social-nextdoor'
	| 'social-email'
	| 'social-sms'
	| 'social-copy'
	| 'social-reddit'
	| 'social-linktree'
	| 'social-qr-code'
	| 'social-snapchat'
	| 'social-telegram'
	| 'social-youtube'
	| 'social-linkedin'
	| 'social-iosShare';

const primary = {
	'primary-changeRed': '#E01A2B',
	'primary-white': '#FFFFFF',
	'primary-greyBackground': '#F2F2F2',
	'primary-black': '#000000',
};

const secondary = {
	'secondary-blue': '#006BA9',
	'secondary-purple': '#842B8F',
	'secondary-green': '#528334',
	'secondary-yellow': '#F9D039',
};

const neutral = {
	'neutral-grey700': '#4D4D4D',
	'neutral-grey600': '#707070',
	'neutral-grey400': '#999999',
	'neutral-grey100': '#E5E5E5',
	'neutral-grey50': '#F2F2F2',
};

const typography = {
	'typography-primary': '#000000',
	'typography-secondary': '#666666',
	'typography-disabled': '#949494',
	'typography-lightPrimary': '#FFFFFF',
	'typography-lightSecondary': '#FFFFFF',
	'typography-lightDisabled': '#FFFFFF',
	'typography-highlight': '#E01A2B',
	'typography-lightHighlight': '#E01A2B',
	'typography-positive': '#528334',
	'typography-critical': '#990000',
};

const status = {
	'status-positive500': '#528334',
	'status-warning500': '#F7BB34',
	'status-critical500': '#D43D3D',
	'status-info900': '#1D5262',
	'status-info500': '#842B8F',
};

const social = {
	'social-facebook': '#3A689B',
	'social-gmail': '#EA4335',
	'social-google': '#4285F4',
	'social-whatsapp': '#25D366',
	'social-twitter': '#000000',
	'social-facebookMessenger': '#0A93FF',
	'social-line': '#06C755',
	'social-vk': '#0077FF',
	'social-instagram': '#FF0076',
	'social-nextdoor': '#8ED500',
	'social-email': '#000000',
	'social-sms': '#528334',
	'social-copy': '#000000',
	'social-reddit': '#FF4500',
	'social-linktree': '#43E660',
	'social-qr-code': '#000000',
	'social-snapchat': '#FFFB00',
	'social-telegram': '#039BE5',
	'social-youtube': '#F61C0D',
	'social-linkedin': '#0077BB',
	'social-iosShare': '#1C1B1F',
};

export type Colors = Record<ColorName, string>;

export const colors: Colors = {
	// Theme-ui expects these keys to be present
	text: typography['typography-primary'],
	background: primary['primary-white'],
	primary: primary['primary-changeRed'],
	secondary: primary['primary-black'],
	accent: secondary['secondary-blue'],
	highlight: primary['primary-greyBackground'],
	muted: neutral['neutral-grey50'],

	...primary,
	...secondary,
	...neutral,
	...typography,
	...status,
	...social,
};
