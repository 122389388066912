/*
 * This file is generated by icons/template.js
 */
const SvgBullhorn = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M19.366 5.675a.42.42 0 0 1-.1.578.404.404 0 0 1-.568-.102.42.42 0 0 1 .1-.578.403.403 0 0 1 .568.102m-3.963 2.828a.42.42 0 0 1 .1-.579.404.404 0 0 1 .568.102.42.42 0 0 1-.1.578.403.403 0 0 1-.568-.101m1.648-1.176a.42.42 0 0 1 .1-.578.403.403 0 0 1 .568.101.42.42 0 0 1-.1.579.403.403 0 0 1-.568-.102M18 10.885a.414.414 0 0 1-.358.46.41.41 0 0 1-.453-.364.413.413 0 0 1 .358-.46.41.41 0 0 1 .453.364m1.999-.237a.414.414 0 0 1-.358.46.41.41 0 0 1-.453-.364.414.414 0 0 1 .358-.46.41.41 0 0 1 .453.364m1.64.223a.41.41 0 0 1-.452-.364.414.414 0 0 1 .358-.461.41.41 0 0 1 .452.364.413.413 0 0 1-.358.46ZM15.227 2.24a.405.405 0 0 1 .542-.201.42.42 0 0 1 .198.552.405.405 0 0 1-.543.2.42.42 0 0 1-.197-.551m-.85 1.855a.405.405 0 0 1 .542-.2.42.42 0 0 1 .197.551.405.405 0 0 1-.542.201.42.42 0 0 1-.197-.552m-.85 1.856a.405.405 0 0 1 .541-.2.42.42 0 0 1 .198.55.405.405 0 0 1-.542.202.42.42 0 0 1-.198-.552Zm-1.11 7.967c-1.105.31-2.173.78-3.05 1.342l-2.078-3.018c.82-.647 1.62-1.507 2.284-2.458.647-.93 1.165-1.943 1.423-2.939l4.622 6.717c-1.008-.094-2.12.055-3.201.356m-2.654 6.151-1.212.864-1.149-3.273 1.211-.863zm-3.789-2.406-.235-.341-.903-1.313-.926-1.345 2.717-1.937 2.065 3zM2.97 16.555q.395-.31.78-.675l1.047 1.52q-.469.243-.89.515zm8.123-11.017a.41.41 0 0 0-.446-.16.42.42 0 0 0-.295.377q-.014.239-.055.475c-.166.967-.689 2.071-1.39 3.074-.7 1.004-1.575 1.91-2.414 2.505l-3.386 2.413a.42.42 0 0 0-.1.578l.27.391c-.365.353-.737.67-1.103.93a.42.42 0 0 0-.1.579l1.392 2.023a.41.41 0 0 0 .423.167.4.4 0 0 0 .145-.065c.366-.26.786-.508 1.236-.737l.27.392a.41.41 0 0 0 .423.167.4.4 0 0 0 .145-.065l.6-.428 1.255 3.57a.42.42 0 0 0 .26.256c.12.04.254.02.358-.055l1.905-1.357a.415.415 0 0 0 .15-.48l-1.33-3.785.188-.133c.837-.598 1.973-1.126 3.14-1.452 1.165-.327 2.364-.45 3.313-.277q.241.044.458.11a.4.4 0 0 0 .45-.153.42.42 0 0 0 .003-.482z"
			clipRule="evenodd"
		/>
	</svg>
);
SvgBullhorn.displayName = 'iconBullhorn';
export default SvgBullhorn;
