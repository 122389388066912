/*
 * This file is generated by icons/template.js
 */
const SvgArrowUpward = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8z" />
	</svg>
);
SvgArrowUpward.displayName = 'iconArrowUpward';
export default SvgArrowUpward;
