/*
 * This file is generated by icons/template.js
 */
const SvgSentimentSatisfiedAlt = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<circle cx={15.5} cy={9.5} r={1.5} />
		<circle cx={8.5} cy={9.5} r={1.5} />
		<circle cx={15.5} cy={9.5} r={1.5} />
		<circle cx={8.5} cy={9.5} r={1.5} />
		<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8m0-2.5c2.33 0 4.32-1.45 5.12-3.5h-1.67c-.69 1.19-1.97 2-3.45 2s-2.75-.81-3.45-2H6.88c.8 2.05 2.79 3.5 5.12 3.5" />
	</svg>
);
SvgSentimentSatisfiedAlt.displayName = 'iconSentimentSatisfiedAlt';
export default SvgSentimentSatisfiedAlt;
