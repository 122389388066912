/*
 * This file is generated by icons/template.js
 */
const SvgGoogle = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 25 24" {...props}>
		<g clipPath="url(#google_svg__a)">
			<path
				fill="currentColor"
				d="M22.055 12.715c0 5.251-3.596 8.988-8.907 8.988A9.19 9.19 0 0 1 3.945 12.5a9.19 9.19 0 0 1 9.203-9.203c2.48 0 4.565.91 6.172 2.408l-2.505 2.409c-3.277-3.162-9.37-.787-9.37 4.386 0 3.21 2.564 5.811 5.703 5.811 3.645 0 5.01-2.612 5.225-3.967h-5.225V11.18h8.762c.085.471.145.924.145 1.536"
			/>
		</g>
		<defs>
			<clipPath id="google_svg__a">
				<path fill="transparent" d="M3.5 3h19v19h-19z" />
			</clipPath>
		</defs>
	</svg>
);
SvgGoogle.displayName = 'iconGoogle';
export default SvgGoogle;
