/*
 * This file is generated by icons/template.js
 */
const SvgPauseCircleFilled = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m-1 14H9V8h2zm4 0h-2V8h2z" />
	</svg>
);
SvgPauseCircleFilled.displayName = 'iconPauseCircleFilled';
export default SvgPauseCircleFilled;
