/*
 * This file is generated by icons/template.js
 */
const SvgPersonAddDisabled = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<circle cx={15} cy={8} r={4} />
		<path d="M23 20v-2c0-2.3-4.1-3.7-6.9-3.9l6 5.9zm-11.6-5.5C9.2 15.1 7 16.3 7 18v2h9.9l4 4 1.3-1.3-21-20.9L0 3.1l4 4V10H1v2h3v3h2v-3h2.9zM6 10v-.9l.9.9z" />
	</svg>
);
SvgPersonAddDisabled.displayName = 'iconPersonAddDisabled';
export default SvgPersonAddDisabled;
