import type { Location } from 'react-router';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

export function isSamePageHref(href: string, location: Location, utilityContext: UtilityContext): boolean {
	if (href.startsWith('#')) return true;
	if (!href.includes('#')) return false;
	const parsedHref = new URL(href, 'http://www.dummy.org');
	const parsedHomeUrl = new URL(utilityContext.resolveUrl('/'));
	return (
		location.pathname === parsedHref.pathname &&
		location.search === parsedHref.search &&
		parsedHomeUrl.origin === parsedHref.origin
	);
}
